import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/System/Info/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import InfoTable01 from 'components/Web_User_Interface/1440p_Series/System/Info/infoTable01';
import InfoTable02 from 'components/Web_User_Interface/1440p_Series/System/Info/infoTable02';
import InfoTable03 from 'components/Web_User_Interface/1440p_Series/System/Info/infoTable03';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "System Menu // Overview",
  "path": "/Web_User_Interface/1440p_Series/System/Overview/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address) - to check whether it is the DDNS address or your port forwarding that is the culprit.",
  "image": "./WebUI_1440p_SearchThumb_System_Overview.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_System_Overview.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='System Menu // Overview' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Info is simply a collection of a lot of system internals that are interesting if you have to start troubleshooting your set up. You DDNS access doesn´t work? Check the DDNS status (has to be successful) and the UPnP Status (most likely has to be deactivated). You can also try accessing your camera via it´s WAN IP (instead of DDNS address) - to check whether it is the DDNS address or your port forwarding that is the culprit.' image='/images/Search/WebUI_1440p_SearchThumb_System_Language.png' twitter='/images/Search/WebUI_1440p_SearchThumb_System_Language.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/System/Info/' locationFR='/fr/Web_User_Interface/1440p_Series/System/Overview/' crumbLabel="System Info" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "system-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#system-menu",
        "aria-label": "system menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`System Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05067149303acf616c2a0daa06744c04/3f8cf/1440p_Settings_System_Info-Overview.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABdUlEQVQoz3WRzW7aQBCAeRNoVWz+jhwQoCiyY6RQE+zYZteujcFO3UolubR1FCUvkGdo01PV1/yq3QaUKOnh25nRar6Z1TYGgwG9Xo9+v49ptDlOat7Xf7Avf2DtfmJf/cK+eniM/3Lr8oFF/ZtZdc+bt++wLYtms0mr1aKhRAolNQ2D2TJGfr4h/XJLursjLL+x+lg/xu+I6pqg+Ir8dM1ZXGGaHd3fbrcxDIOGEim63S6maWIdHyFCn0wK1rFkFXiE/oLAW3C+dDX+2Rx/6XI6O9F9nU5HR8VhQ1WoCSeOQ1lcsN0VlPUFUsbEyQfWec463xBFK86DgDCKOJ3PD8I9zzZUQtu2KYuSvFgjMoHnefi+T57nVFWFEIIkSXQ+fyI8bLj/kP2TlbAoS7I0Q6yEliniOGa73WqZlFIPeFWojqdCx3HYbDakaUqapbo5DEOiKCLLsme167r/F6pN1cVwOGQymTAejzUqf43pdMpoNHoh/AtNyyA6+zgvmAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05067149303acf616c2a0daa06744c04/e4706/1440p_Settings_System_Info-Overview.avif 230w", "/en/static/05067149303acf616c2a0daa06744c04/d1af7/1440p_Settings_System_Info-Overview.avif 460w", "/en/static/05067149303acf616c2a0daa06744c04/7f308/1440p_Settings_System_Info-Overview.avif 920w", "/en/static/05067149303acf616c2a0daa06744c04/f1ddf/1440p_Settings_System_Info-Overview.avif 1293w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05067149303acf616c2a0daa06744c04/a0b58/1440p_Settings_System_Info-Overview.webp 230w", "/en/static/05067149303acf616c2a0daa06744c04/bc10c/1440p_Settings_System_Info-Overview.webp 460w", "/en/static/05067149303acf616c2a0daa06744c04/966d8/1440p_Settings_System_Info-Overview.webp 920w", "/en/static/05067149303acf616c2a0daa06744c04/dfa64/1440p_Settings_System_Info-Overview.webp 1293w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05067149303acf616c2a0daa06744c04/81c8e/1440p_Settings_System_Info-Overview.png 230w", "/en/static/05067149303acf616c2a0daa06744c04/08a84/1440p_Settings_System_Info-Overview.png 460w", "/en/static/05067149303acf616c2a0daa06744c04/c0255/1440p_Settings_System_Info-Overview.png 920w", "/en/static/05067149303acf616c2a0daa06744c04/3f8cf/1440p_Settings_System_Info-Overview.png 1293w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05067149303acf616c2a0daa06744c04/c0255/1440p_Settings_System_Info-Overview.png",
              "alt": "Web User Interface - 1440p Series - System Info",
              "title": "Web User Interface - 1440p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable01 mdxType="InfoTable01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0c6216ac68b1997fad87837dbeefdcc5/e4611/1440p_Settings_System_Info-Network.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABSElEQVQoz3WSy07CQBSGeQ92hN64BPaQ2CbSFhMTbGemzE0qGBYucCHuXLkx8TXEJYlv+JsZLKmIiy9n5iTnm/8k0+j1euh2uxbfc5Gu3zB72eNqu8N0+2lJn3ZIq/uzuX+Av35hTDYIvDZGozGazSZarRYanU7Hykz1PA/pdQaqVsh5CaZWIHIJvXoAX6xB5dL2cr4A0/eIJlM4Tht+ENhZx3EOQkPw04wnlygowZxRWwUvLLdaQUsByeegJEN+M0MYXsB1XTtrMOdGte5RGCfgXEBKCaWUrZRSSKkghARjBbIsByEEYRT9FZ4mTJLEpiPlBuzxHUTcgdEci7KE1tpSFMVBGIbnhVVC3/cRxzGEEFBKQyptq0loBBVZlv0vNIf6yiahScAYO1JJ6phHonMr14UmoflGw+EQg8HgF6Z3Sr/ftzN14TcIyxx9OOUK/QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c6216ac68b1997fad87837dbeefdcc5/e4706/1440p_Settings_System_Info-Network.avif 230w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/d1af7/1440p_Settings_System_Info-Network.avif 460w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/7f308/1440p_Settings_System_Info-Network.avif 920w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/0407b/1440p_Settings_System_Info-Network.avif 1298w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0c6216ac68b1997fad87837dbeefdcc5/a0b58/1440p_Settings_System_Info-Network.webp 230w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/bc10c/1440p_Settings_System_Info-Network.webp 460w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/966d8/1440p_Settings_System_Info-Network.webp 920w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/94575/1440p_Settings_System_Info-Network.webp 1298w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0c6216ac68b1997fad87837dbeefdcc5/81c8e/1440p_Settings_System_Info-Network.png 230w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/08a84/1440p_Settings_System_Info-Network.png 460w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/c0255/1440p_Settings_System_Info-Network.png 920w", "/en/static/0c6216ac68b1997fad87837dbeefdcc5/e4611/1440p_Settings_System_Info-Network.png 1298w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0c6216ac68b1997fad87837dbeefdcc5/c0255/1440p_Settings_System_Info-Network.png",
              "alt": "Web User Interface - 1440p Series - System Info",
              "title": "Web User Interface - 1440p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable02 mdxType="InfoTable02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1397ed0c3bdd450aed1e7faedeef1919/e4611/1080p_Settings_System_Info-Downloads.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABjUlEQVQoz3WPPW/TUBSG8z+yRXVsxzaKsjZRnKhp8+Xm+l77moBDA40Q9YCQaJeKiYURwcTARjsCRayIASEhIfExMzEh8SseZDeRggjDo/MhvY/OKbmui+M4BZZZpX/7EeLBS4YnZwyOzxmcLDk+p3//bMkLpg8vaEZ3sc0ttreblMtlKpUKpVqtVsjyapom/UCi00Pi67dIbiyIrt1Ezy7n9DBjtshQ0zl6tqDTG2AYW1i2XWQNw7gU5tjL5d7uDjKcEIyH7AcjQjFBThRX4wgdK5I4QooJSgr8dptqtVpkc/K+tHp3tex2uzx+8pQvX79x8eo1o/2A5M4B0SxFiBAhBCIMiaIYv9P5V7h+YV6brRYf3r/j188ffP/8iVEwJj1KmR5MSXRCkiRorQt8398sXF1o2TV22i2y52+59/E382dvCIMxWiXoWBNFEUqpgrzfKMybldC0LAa7PYbzjN7RKXvzDCVDpFJIKf8ijmM6m15eF1qWhet5NK64NByLhudQr9f/i+d5RWZd+AffdCRHJRr+ogAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1397ed0c3bdd450aed1e7faedeef1919/e4706/1080p_Settings_System_Info-Downloads.avif 230w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/d1af7/1080p_Settings_System_Info-Downloads.avif 460w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/7f308/1080p_Settings_System_Info-Downloads.avif 920w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/0407b/1080p_Settings_System_Info-Downloads.avif 1298w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1397ed0c3bdd450aed1e7faedeef1919/a0b58/1080p_Settings_System_Info-Downloads.webp 230w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/bc10c/1080p_Settings_System_Info-Downloads.webp 460w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/966d8/1080p_Settings_System_Info-Downloads.webp 920w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/94575/1080p_Settings_System_Info-Downloads.webp 1298w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1397ed0c3bdd450aed1e7faedeef1919/81c8e/1080p_Settings_System_Info-Downloads.png 230w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/08a84/1080p_Settings_System_Info-Downloads.png 460w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/c0255/1080p_Settings_System_Info-Downloads.png 920w", "/en/static/1397ed0c3bdd450aed1e7faedeef1919/e4611/1080p_Settings_System_Info-Downloads.png 1298w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1397ed0c3bdd450aed1e7faedeef1919/c0255/1080p_Settings_System_Info-Downloads.png",
              "alt": "Web User Interface - 1440p Series - System Info",
              "title": "Web User Interface - 1440p Series - System Info",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoTable03 mdxType="InfoTable03" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      